<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <v-row justify="space-between">
          <v-col>
            <button @click="doFilter" class="btn btn-primary">
              Filter <i :class="filterIcon"></i>
            </button>
          </v-col>
          <v-col cols="5" v-if="holidayInfo">
            <p class="text-orange-lighten-1">
              Public holiday or weekend there’s no settlement, please make an
              accumulated top up on the weekdays
            </p>
          </v-col>
          <v-col cols="5" v-if="settlementInfo">
            <p class="text-orange-lighten-1">
              Please input the settlement amount you want to settle with this
              option : Local Bank, Crypto, or Payout Top Up.
            </p>
            <p class="text-orange-lighten-1">
              Please submit it within Local Indonesia Time (GMT +7, 00:01 AM to
              10:00 AM). If you failed to process it within the hours period we
              decided, it will be transferred tomorrow.
            </p>
          </v-col>
          <!-- <v-col v-if="can_show_chart">
            <v-btn
              class="ma-2"
              color="primary"
              @click="this.$emit('toggleExpand')"
            >
              {{expand ? 'Hide Graphic' : 'Show Graphic'}}
            </v-btn>
          </v-col> -->
          <v-col v-if="cardInfo">
            <TopShapeItem
              :customCols="'col'"
              :loading="loading"
              menulink="/settlement"
              title="Ready To Settle"
              :value="settle"
              subvalue=""
              :imgsrc="require('@/assets/img/idr-icon.png')"
              :tooltipMessage="tooltipMessage"
              :seeDetail="true"
            ></TopShapeItem>
          </v-col>
          <v-col v-if="cardInfo">
            <TopShapeItem
              :customCols="'col'"
              :loading="loading"
              title="Receivables"
              :value="receivables"
              subvalue=""
              :imgsrc="require('@/assets/img/idr-icon.png')"
            ></TopShapeItem>
          </v-col>
          <v-col class="text-right" v-if="can_download">
            <v-menu class="mr-3">
              <template v-slot:activator="{ props }">
                <v-btn
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-download"
                >
                  Download
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in downloadItems"
                  :key="index"
                  :value="index"
                  @click="f_submitdownload(item.id, dataJson)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <div v-show="collapsed" class="card-body">
          <h5 class="font-weight-bolder">Filter</h5>
          <div class="row">
            <div class="col-md-3" v-if="f_partner_name">
              <label>Merchant Name</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_partner_name"
              />
            </div>
            <div class="col-md-3" v-if="f_partner_code">
              <label>Merchant</label>
              <VueMultiselect
                :multiple="true"
                v-model="dataJson.filter_partner_code"
                :options="dataMerchant"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Merchant"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_partner_id">
              <label>Merchant</label>
              <VueMultiselect
                :multiple="f_mapping_issuer ? false : true"
                v-model="dataJson.filter_partner_id"
                :options="f_mapping_issuer ? dataAllMerchants : dataMerchant"
                :taggable="false"
                :label="f_mapping_issuer ? 'partner_name' : 'name'"
                :track-by="f_mapping_issuer ? 'partner_id' : 'id'"
                placeholder="Pick Merchant"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_partner_status">
              <label>Merchant Status</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_partner_status"
              />
            </div>
            <div class="col-md-3" v-if="f_date">
              <label>Date</label>
              <input
                class="form-control"
                type="date"
                v-model="dataJson.filter_date"
              />
            </div>
            <div class="col-md-3" v-if="f_date_range">
              <label>Start Date</label>
              <input
                class="form-control"
                type="date"
                v-model="dataJson.filter_date_range_start"
              />
            </div>
            <div class="col-md-3" v-if="f_date_range">
              <label>End Date</label>
              <input
                class="form-control"
                type="date"
                v-model="dataJson.filter_date_range_end"
              />
            </div>
            <div class="col-md-3" v-if="f_trx_type">
              <label>Type</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_type"
                :options="modelTrxTypes"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Type"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_trx_type_2">
              <label>Type</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_type"
                :options="modelTrxTypes2"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Type"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_refno">
              <label>Transaction Ref No.</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_refno"
              />
            </div>
            <div class="col-md-3" v-if="f_merchantrefno">
              <label>Merchant Ref No.</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_merchant_refno"
              />
            </div>
            <div class="col-md-3" v-if="f_sourcerefno">
              <label>Merchant Ref No.</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_sourcerefno"
              />
            </div>
            <div class="col-md-3" v-if="f_amount">
              <label>Amount</label>
              <input
                class="form-control"
                type="number"
                @keydown="validateNumber"
                v-model="dataJson.filter_amount"
              />
            </div>
            <div class="col-md-3" v-if="f_destination">
              <label>Destination</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_destination"
              />
            </div>
            <div class="col-md-3" v-if="f_paymentid">
              <label>Payment Id</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_paymentid"
              />
            </div>
            <div class="col-md-3" v-if="f_channel">
              <label>Channel</label>
              <VueMultiselect
                :multiple="false"
                v-model="channelData"
                :options="dataChannel"
                :taggable="false"
                label="product_name"
                track-by="payment_id"
                @remove="onRemove"
                @select="onSelect"
                placeholder="Pick channel"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_payment_id">
              <label>Channel</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_payment_id"
                :options="dataAllChannels"
                :taggable="false"
                label="product_name"
                track-by="payment_id"
                placeholder="Pick Channel"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_pg_id">
              <label>Issuer</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_pg_id"
                :options="dataAllIssuers"
                :taggable="false"
                label="pg_name"
                track-by="pg_id"
                placeholder="Pick Issuer"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_name">
              <label>Name/Description</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_name"
              />
            </div>
            <div class="col-md-3" v-if="f_feemodel">
              <label>Fee Model</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_feemodel"
                :options="modelOptions"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Feemodel"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_category">
              <label>Category</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_category"
              />
            </div>
            <div class="col-md-3" v-if="f_status">
              <label>Status</label>
              <VueMultiselect
                :multiple="false"
                v-model="statusData"
                :options="dataStatus"
                :taggable="false"
                label="sts_desc"
                track-by="sts"
                @remove="onRemoveStatus"
                @select="onSelectStatus"
                placeholder="Pick status"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_user_email">
              <label>Username/ Email</label>
              <input
                class="form-control"
                type="email"
                v-model="dataJson.filter_email"
              />
            </div>
            <div class="col-md-3" v-if="f_code">
              <label>Code</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_code"
              />
            </div>
            <div class="col-md-3" v-if="f_account">
              <label>Account</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_account"
              />
            </div>
            <div class="col-md-3" v-if="f_user_phone">
              <label>Mobilephone</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_phone"
              />
            </div>
            <div class="col-md-3" v-if="f_user_fullname">
              <label>Fullname</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_fullname"
              />
            </div>
            <div class="col-md-3" v-if="f_username">
              <label>Username</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_username"
              />
            </div>
            <div class="col-md-3" v-if="f_activity">
              <label>Activity</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_activity"
              />
            </div>
            <div class="col-md-3" v-if="f_user_id">
              <label>User Id</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_user_id"
              />
            </div>
            <div class="col-md-3" v-if="f_ip">
              <label>IP Address</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_ip"
              />
            </div>
            <div class="col-md-3" v-if="f_reff_no">
              <label>Transaction Ref No</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_refno"
              />
            </div>
            <div class="col-md-3" v-if="f_merchant_reff_no">
              <label>Merchant Ref No</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_merchant_refno"
              />
            </div>
            <div class="col-md-3" v-if="f_trx_id">
              <label>Transaction ID</label>
              <input
                class="form-control"
                type="text"
                v-model="dataJson.filter_trx_id"
              />
            </div>
            <div class="col-md-3" v-if="f_type">
              <label>Type</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_type"
                :options="modelTypes"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Type"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_overdraft_type">
              <label>Type</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_overdraft_type"
                :options="modelOverdraftTypes"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Type"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_user_status">
              <label>Status</label>
              <select
                class="form-control"
                v-model="dataJson.filter_user_status"
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div class="col-md-3" v-if="f_status_success">
              <label>Status</label>
              <select class="form-control" v-model="dataJson.filter_success">
                <option value="1">Success</option>
                <option value="0">Failed</option>
              </select>
            </div>
            <div class="col-md-3" v-if="f_overdraft_status">
              <label>Status</label>
              <VueMultiselect
                :multiple="false"
                v-model="dataJson.filter_status"
                :options="modelOverdraftStatus"
                :taggable="false"
                label="text"
                track-by="id"
                placeholder="Pick Status"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3" v-if="f_active">
              <span class="text-sm text-bold">Active</span>
              <div class="form-check form-switch ms-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault30"
                  v-model="dataJson.filter_active"
                />
              </div>
            </div>
          </div>
          <div class="card-body" v-if="f_loading">
            <img :src="imageLoading" style="width: 100px; height: 80px" />
          </div>
          <div class="card-body" v-else>
            <div class="row">
              <div class="col-md-3">
                <button
                  @click="f_submit(dataJson)"
                  class="btn btn-success mr-2"
                  type="button"
                >
                  Search
                </button>
                <button @click="doClear" class="btn btn-info" type="button">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import TopShapeItem from "@/components/TopShapeItem";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";

export default {
  name: "TableFilter",
  components: {
    TopShapeItem,
  },
  props: {
    f_loading: Boolean,
    f_action: String,
    f_feemodel: Boolean,
    f_submit: undefined,
    f_submitdownload: undefined,
    f_refno: Boolean,
    f_amount: Boolean,
    f_date: Boolean,
    f_destination: Boolean,
    f_download: Boolean,
    f_status: Boolean,
    f_channel: Boolean,
    f_sourcerefno: Boolean,
    f_merchantrefno: Boolean,
    f_partner_name: Boolean,
    f_partner_code: Boolean,
    f_partner_id: Boolean,
    f_mapping_issuer: Boolean,
    f_partner_status: Boolean,
    f_user_email: Boolean,
    f_user_phone: Boolean,
    f_user_fullname: Boolean,
    f_user_status: Boolean,
    f_username: Boolean,
    f_activity: Boolean,
    f_status_success: Boolean,
    f_overdraft_status: Boolean,
    f_overdraft_type: Boolean,
    f_user_id: Boolean,
    f_ip: Boolean,
    f_paymentid: Boolean,
    f_payment_id: Boolean,
    f_pg_id: Boolean,
    f_name: Boolean,
    f_category: Boolean,
    f_date_range: Boolean,
    f_active: Boolean,
    f_code: Boolean,
    f_account: Boolean,
    f_reff_no: Boolean,
    f_merchant_reff_no: Boolean,
    f_type: Boolean,
    f_trx_type: Boolean,
    f_trx_type_2: Boolean,
    f_trx_id: Boolean,
    can_download: Boolean,
    can_show_chart: Boolean,
    expand: Boolean,
    balanceData: Object,
    cardInfo: Boolean,
    holidayInfo: Boolean,
    settlementInfo: Boolean,
  },
  emits: ["toggleExpand"],
  methods: {
    validateNumber: (event) => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    doFilter() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        this.filterIcon = "fa-solid fa-chevron-up";
      } else {
        this.filterIcon = "fa-solid fa-chevron-down";
      }
    },
    doClear() {
      this.dataJson.filter_date = "";
      this.dataJson.filter_date_range_start = "";
      this.dataJson.filter_date_range_end = "";
      this.dataJson.filter_refno = "";
      this.dataJson.filter_amount = "";
      this.dataJson.filter_destination = "";
      this.dataJson.filter_channel = "";
      this.dataJson.filter_status = "";
      this.dataJson.filter_username = "";
      this.dataJson.filter_activity = "";
      this.dataJson.filter_success = undefined;
      this.dataJson.filter_sourcerefno = "";
      this.dataJson.filter_partner_name = "";
      this.dataJson.filter_partner_code = "";
      this.dataJson.filter_partner_id = "";
      this.dataJson.filter_partner_status = "";
      this.dataJson.filter_email = "";
      this.dataJson.filter_phone = "";
      this.dataJson.filter_fullname = "";
      this.dataJson.filter_user_status = "";
      this.dataJson.filter_user_id = "";
      this.dataJson.filter_ip = "";
      this.dataJson.filter_paymentid = "";
      this.dataJson.filter_payment_id = "";
      this.dataJson.filter_pg_id = "";
      this.dataJson.filter_name = "";
      this.dataJson.filter_category = "";
      this.dataJson.filter_active = "";
      this.dataJson.filter_code = "";
      this.dataJson.filter_account = "";
      this.dataJson.filter_feemodel = "";
      this.dataJson.filter_refno = "";
      this.dataJson.filter_merchant_refno = "";
      this.dataJson.filter_trx_id = "";
      this.dataJson.filter_type = "";
      this.dataJson.filter_overdraft_type = "";
      this.channelData = [];
    },
    getFeeModel() {
      this.loading = true;
      DashboardService.getFeeModel(this.dataJsonInfo).then((response) => {
        if (response.data.code == 1) {
          this.modelOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    loadChannelData() {
      this.loading = true;
      DashboardService.getChannelList(this.f_action).then((response) => {
        if (response.data.code == 1) {
          this.dataChannel = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    loadStatusData() {
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_STATUS_CODE_LIST,
        this.dataJson
      ).then((response) => {
        if (response.data.code == 1) {
          this.dataStatus = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    onSelectStatus(option) {
      this.dataJson.filter_status = option.sts;
    },
    onRemove() {
      this.dataJson.filter_channel = "";
    },
    onRemoveStatus() {
      this.dataJson.filter_status = "";
    },
    onSelect(option) {
      this.dataJson.filter_channel = option.payment_id;
    },
    getPartnerLink() {
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_PARTNER_LINK,
        this.dataJson
      ).then((response) => {
        if (response.data.code == 1) {
          this.dataMerchant = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getMerchant() {
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_LINKED_PARTNER,
        this.dataJson
      ).then((response) => {
        if (response.data.code == 1) {
          this.dataMerchant = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getAllMerchants() {
      this.loading = true;
      DashboardService.getAllMerchants().then((response) => {
        if (response.data.code == 1) {
          this.dataAllMerchants = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getAllChannels() {
      this.loading = true;
      DashboardService.getAllChannels().then((response) => {
        if (response.data.code == 1) {
          this.dataAllChannels = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getAllIssuers() {
      this.loading = true;
      DashboardService.getAllIssuers().then((response) => {
        if (response.data.code == 1) {
          this.dataAllIssuers = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
  },
  data: () => ({
    form: "",
    control: "",
    collapsed: false,
    filterIcon: "fa-solid fa-chevron-down",
    imageLoading: require("@/assets/img/cube_loading.gif"),
    select2settingP: {
      dropdownAutoWidth: false,
      multiple: true,
      width: "100%",
    },
    loading: true,
    dataMerchant: [{}],
    dataAllMerchants: [],
    dataAllChannels: [],
    dataAllIssuers: [],
    modelTypes: [
      { text: "RESPONSE", id: "RESPONSE" },
      { text: "BACKEND", id: "BACKEND" },
    ],
    modelTrxTypes: [
      { text: "MERCHANT WITHDRAWAL", id: "MERCHANT WITHDRAWAL" },
      { text: "LOCAL SETTLEMENT", id: "LOCAL SETTLEMENT" },
      { text: "USDT SETTLEMENT", id: "USDT SETTLEMENT" },
      { text: "TOP UP", id: "TOP UP" },
      { text: "REVERSAL", id: "REVERSAL" },
      { text: "OVERDRAFT", id: "OVERDRAFT" },
    ],
    modelTrxTypes2: [
      { text: "TOP UP", id: "TOP UP" },
      { text: "PAYMENT", id: "PAYMENT" },
    ],
    modelOptions: [],
    channelData: [],
    statusData: [],
    dataChannel: [],
    dataStatus: [],
    modelOverdraftTypes: [
      { text: "Daily", id: 1 },
      { text: "Public Holiday", id: 2 },
    ],
    modelOverdraftStatus: [
      { text: "ACTIVE", id: 1 },
      { text: "PAID", id: 2 },
    ],
    dataJson: {
      download: false,
      filter_date: "",
      filter_refno: "",
      filter_amount: "",
      filter_destination: "",
      filter_channel: "",
      filter_status: "",
      filter_sourcerefno: "",
      filter_partner_name: "",
      filter_partner_code: "",
      filter_partner_id: "",
      filter_partner_status: "",
      filter_email: "",
      filter_phone: "",
      filter_fullname: "",
      filter_user_status: "",
      filter_username: "",
      filter_activity: "",
      filter_success: undefined,
      filter_user_id: "",
      filter_ip: "",
      filter_paymentid: "",
      filter_payment_id: "",
      filter_pg_id: "",
      filter_name: "",
      filter_category: "",
      filter_date_range_start: "",
      filter_date_range_end: "",
      filter_active: "",
      filter_code: "",
      filter_account: "",
      filter_overdraft_type: "",
      filter_trx_id: "",
    },
    settle: 0,
    receivables: 0,
    downloadItems: [
      { title: "As CSV", id: "csv" },
      { title: "As Excel", id: "excel" },
      { title: "As PDF", id: "pdf" },
    ],
    tooltipMessage:
      " This amount will be transferred automatically to payout balance (time exactly for auto transfer ?)" +
      "except weekend and public holiday. If you wish to stop it or wants to split please click link on label Ready to Settle or" +
      "go to Settlement page",
    noteMessage:
      "Please input the settlement amount you want to settle with this option : Local Bank, Crypto, or Payout Top Up </br>" +
      "Please submit it within Local Indonesia Time (GMT +7, 00:01 AM to 10:00 AM). If you failed to process it within the hours period we decided, it will be transferred tomorrow.",
  }),
  beforeMount() {
    if (this.f_partner_code) {
      this.getPartnerLink();
    }
    if (this.f_partner_id) {
      if (this.f_mapping_issuer) {
        this.getAllMerchants();
        return;
      }
      this.getMerchant();
    }
    if (this.f_channel) {
      this.loadChannelData();
    }
    if (this.f_status) {
      this.loadStatusData();
    }
    if (this.f_feemodel) {
      this.getFeeModel();
    }
  },
  mounted() {
    if (this.f_mapping_issuer && this.f_partner_id) {
      this.getAllMerchants();
    }
    if (this.f_payment_id) {
      this.getAllChannels();
    }
    if (this.f_pg_id) {
      this.getAllIssuers();
    }
  },
  watch: {
    expand: function () {},
    balanceData: {
      immediate: false,
      deep: true,
      handler() {
        this.settle = this.balanceData.total.ready_settle;
        this.receivables = this.balanceData.total.receivables;
      },
    },
  },
};
</script>

<style scoped></style>
